import React from "react";
import { Card, Map, Divider } from "../../atoms";
import { CardHeader } from "..";




const MapCard = ({ divider, iconContents, ...card }) => {
	return (
		<React.Fragment>
			{iconContents ? <Card {...card}>
				<CardHeader {...iconContents} />
				<Divider {...divider} />
				<Map {...card} />
				<Divider {...divider} />
			</Card> : <Card {...card}>
				<Map {...card} />
			</Card>}

		</React.Fragment>
	);
};

export default MapCard;
