import React from 'react';
import {
    Footer as FooterTemplate,
} from '../../components/template'
import footerProps from './data'

const Footer = () => {
    return <FooterTemplate {...footerProps} />
}

export default Footer;