import { Text, UnitText, Icon, Title } from '../../atoms'
import styled from 'styled-components'


const StyledIconContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0 1rem;    
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
`

const StyledContents = styled.div`
    text-align: right;
    line-height: 1.25;
    opacity: 1;
    background: transparent;
    box-shadow: none;
`;


const StyledIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -2rem;
    opacity: 1;
    background: ${props => props.bgColor};
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: ${props => props.boxShadow};
`;


const CardHeader = ({ icon, contents }) => {
    return <StyledIconContent >
        <StyledIcon {...icon}>
            <Icon {...icon.iconProps} />
        </StyledIcon>
        <StyledContents>
            <Text {...contents.category} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                <Title {...contents.value} />
                <UnitText {...contents.unit} />
            </div>
        </StyledContents>
    </StyledIconContent >
}

export default CardHeader

