import React from "react";
import styled from "styled-components";
import { logoImg } from "../../../config/images"





const StyledLogo = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin: ${props => props.margin};
    width: ${props => props.width};
    height:${props => props.height};
    z-index:${props => props.zIndex};
`;

const LogoImg = styled.img`
    
`;



const Logo = ({ src = logoImg, width = '1.3rem', zIndex = 1, margin }) => {
    const needProps = {
        width,
        zIndex,
        margin
    }
    return <StyledLogo {...needProps} >
        <LogoImg src={src} width="100%" textAlign="center" />
    </StyledLogo>
}



export default Logo