
import GlobalStyle from './config/styles'
import React from 'react'
import { RecoilRoot } from 'recoil'
import Main from './pages/main'
import SideNav from './pages/side_nav'


function App() {

  return (
    <div className="App">
      <RecoilRoot>
        <GlobalStyle />
        <SideNav />
        <Main />
      </RecoilRoot>
    </div >
  );
}

export default App;