import React, { useRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RouteTitle, InputSet } from '../../organisms'
import { viewSize } from "../../../config/palette";


const StyledDiv = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem;
    width: 100%;
    @media (max-width: ${viewSize.mobile}){
        flex-direction: column;
    }
`

const StyledTopNav = styled.div`
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: sticky;
    left: auto;
    right: 0px;
    box-shadow: none;
    backdrop-filter: none;
    color: rgb(123, 128, 154);
    top: 0.75rem;
    min-height: 4.6875rem;
    display: grid;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0.75rem;
    padding: 0.5rem 0px;
    background-color: transparent;
    z-index: 2;
    ${(props) =>
        props.sticky &&
        css`
            background-color: black;
            box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
            backdrop-filter: saturate(200%) blur(1.875rem);
            background-color: rgba(255, 255, 255, 0.8);
            color: rgb(52, 71, 103);
    `}
`

const TopNav = ({ routeTitleProps, inputSetProps, }) => {
    const topNavRef = useRef();
    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        window.onscroll = () => {
            const targetTop = topNavRef.current.getBoundingClientRect().top;
            window.pageYOffset > targetTop ? setIsSticky(true) : setIsSticky(false)
        }
    });
    return (
        <StyledTopNav ref={topNavRef} sticky={isSticky}>
            <StyledDiv>
                <RouteTitle {...routeTitleProps} />
                <InputSet {...inputSetProps} />
            </StyledDiv>
        </StyledTopNav>
    )
}

export default TopNav