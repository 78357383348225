import React from "react";
import styled from "styled-components";
import * as MdIcon from 'react-icons/md'
import * as AiIcon from 'react-icons/ai'


const StyledIcon = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
    cursor: ${props => props.cursor};
    padding: ${props => props.padding};
    font-size : ${props => props.size};
    color: ${props => props.color};
`;


const Icon = ({ maker = 'Md', iconName, cursor, size, color, padding = '0', onClick }) => {
    const needProps = {
        size,
        color,
        cursor,
        padding,
        onClick
    }
    const iconMaker = (maker === 'Md') ? MdIcon : AiIcon
    const icon = React.createElement(iconMaker[iconName])


    return <StyledIcon {...needProps}>{icon}</StyledIcon>
}


export default Icon