import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Divider } from "../../atoms";
import { TitleLogo } from "../../molecules";
import { MenuLists } from "../../organisms";
import { sideNavHide } from "../../../recoils/atom";
import { useRecoilState } from "recoil";
import { colorGradient, viewSize } from "../../../config/palette";
import {
  전라남도,
  해남,
  어촌계,
  완도군,
  신안군,
  고흥군,
} from "../../../config/images";

const StyledSideNav = styled.div`
  width: 250px;
  overflow: scroll;
  background: ${colorGradient.lightBlack.color};
  box-shadow: ${colorGradient.lightBlack.shadow};
  border-radius: 0.75rem;
  position: fixed;
  top: 0;
  outline: 0;
  flex: 1 0 auto;
  margin: 1rem;
  margin-bottom: inherit;
  height: calc(100vh - 2rem);
  left: 0;
  z-index: 3;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  ${(props) =>
    props.isHide
      ? css`
          transform: translateX(-20em);
        `
      : css`
          transform: translateX(0em);
        `}
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

const SideContainer = styled.div`
  position: absolute;
  bottom: 5%;

  @media screen and (max-width: ${viewSize.mobile}) {
    position: relative;
    margin-top: 5rem;
  }
`;

const SideFooter = styled.div`
  margin-bottom: 0;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
  justify-content: space-around;
  padding: 1rem 0;
  > img {
    margin: 0.25rem auto;
  }
  margin: 0.25rem;
`;

const SideNav = ({ menuListsProps, titleLogoProps }) => {
  const [isHide, setHide] = useRecoilState(sideNavHide);

  const handleResize = () => {
    window.innerWidth <= 1200 ? setHide(true) : setHide(false);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <StyledSideNav isHide={isHide}>
      <TitleLogo {...titleLogoProps} />
      <Divider />
      <MenuLists {...menuListsProps} />
      <SideContainer>
        <SideFooter>
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            지자체
          </div>
          <img src={전라남도} width={100} alt="전라남도이미지" />
          <img src={완도군} width={100} alt="완도군 이미지" />
          <img src={신안군} width={100} alt="신안군 이미지" />
          <img src={고흥군} width={100} alt="고흥군 이미지" />
          <img src={해남} width={100} alt="해남 이미지" />
        </SideFooter>
        <SideFooter>
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            어촌계
          </div>
          <img src={어촌계} width={200} alt="어촌계 이미지" />
        </SideFooter>
      </SideContainer>
    </StyledSideNav>
  );
};
export default SideNav;
