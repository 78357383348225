import React from "react";
import styled from "styled-components";
import { Icon } from '../../atoms'
import { sideNavHide } from '../../../recoils/atom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { sideNavGetHide } from '../../../recoils/selectors'


const StyledIconGroup = styled.div`
    display: flex;
    align-items: center;
    div:nth-child(3){
        display: ${props => props.isHide ? 'flex' : 'none'}
    }
    div:nth-child(4){
        display: ${props => props.isHide ? 'none' : 'flex'}
    }
    @media (min-width: 1200px){
        div:nth-child(3){
        display: none;
    }
        div:nth-child(4){
        display: none;
    }
    }
`;


const IconGroup = ({ icons }) => {
    const [, setHide] = useRecoilState(sideNavHide);
    const isHide = useRecoilValue(sideNavGetHide)

    icons.map((i, index) =>
        i.alias === 'menu_icon' ?
            i.onClick = () => isHide ? setHide(false) : setHide(true) : null)

    return <StyledIconGroup isHide={isHide}>
        {
            icons.map((i, index) => <Icon key={index} {...i} />)
        }
    </StyledIconGroup >

}

export default IconGroup

