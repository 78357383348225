import styled from "styled-components";
import { colorPalette } from "../../../config/palette";
import { Link } from "react-router-dom";

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;
export const Marker = styled.div`
	background-image: ${(props) => props.src && `url(${props.src});`};
	width: 29px;
	height: 46px;
	background-repeat: no-repeat;
	margin-top: -40px;
	margin-left: -15px;
	position: relative;
`;

export const MarkerPop = styled.div`
	width: 220px;
	height: 50px;
	background-color: rgba(255, 255, 255);
	border-radius: 8px;
	position: absolute;
	top: -61px;
	left: -25px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	padding: 5px;
`;
export const Info = styled.div`
	display: flex;
	justify-content: center;
	margin: 0.05rem 0;

	p {
		margin: 0;
		margin-right: 0.2rem;
		font-size: 0.75rem;
		font-weight: 500;
		color: ${colorPalette.primary};
	}
	p:nth-child(1) {
		font-weight: 400;
		color: ${colorPalette.secondary};
	}
`;

export const LinkStyle = styled(Link)`
	width: 29px;
	height: 46px;
`;
