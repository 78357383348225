import React from "react";
import styled from "styled-components";


const StyledTitle = styled.div`
	display: flex;
    justify-content:center;
	align-items: center;
	font-weight: bold;
	color: ${props => props.color};
    font-size: ${props => props.fontSize};
`;


const Span = styled.span`
    width: 100%;
    
`;

const Title = ({ children, fontSize, color = 'inherit' }) => {
    const needProps = {
        color,
        fontSize,
    };
    return (
        <StyledTitle {...needProps}>
            <Span>
                {children}
            </Span>
        </StyledTitle>
    );
};

export default Title