export const desktopFontSize = {
    xs: "0.75rem",
    sm1: "0.875rem",
    sm2: "0.938rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    xl2: "1.5rem",
    xl3: "1.75rem",
    xl4: "2rem",
    xl5: "3rem",
    xl6: "3.75rem",
    xl7: "4.375rem",
    xl8: "7.5rem",
};

export const mobileFontSize = {
    xs1: "0.5rem",
    xs2: "0.625rem",
    sm: "0.75rem",
    base: "0.875rem",
    lg: "0.938rem",
    xl: "1rem",
    xl2: "1.125rem",
    xl3: "1.25rem",
    xl4: "1.75rem",
    xl5: "2rem",
    xl6: "2.25rem",
    xl7: "3.25rem",
};

export const viewSize = {
    smallMobile: "36rem",
    mobile: "48rem",
    tablet: "75rem",
    // desktop: "130rem",
};

export const fontWeight = {
    black: 900,
    extraBold: 800,
    bold: 700,
    semiBold: 600,
    medium: 500,
    normal: 400,
};

export const colorGradient = {
    red: {
        color: 'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    blue: {
        color: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    green: {
        color: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    black: {
        color: 'linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    lightBlack: {
        color: 'linear-gradient(195deg, #42424a, #191919)',
        shadow: '0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%)',
    },
    yellow: {
        color: 'linear-gradient(195deg, #FFDD00, #FBB034)',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, #FFFF99 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    indigo: {
        color: 'linear-gradient(195deg, #5e718d, #003458)',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, #acb5c4 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    dark_green: {
        color: 'linear-gradient(195deg, #6f8668, #2e4f26)',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, #b5c1b1 0rem 0.4375rem 0.625rem -0.3125rem',
    },
    gray: {
        color: 'linear-gradient(195deg, #969696, #666666)',
        shadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, #c9c9c9 0rem 0.4375rem 0.625rem -0.3125rem',

    }
}


export const dividerGradient = {
    white: 'linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0))',
    gray: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0))',
    black: 'linear-gradient(to right, rgb(66,66,74), black, rgb(66,66,74))',
}




export const colorPalette = {
    primary: 'rgb(52, 71, 103)',
    secondary: "rgb(123, 128, 154)",
    white: "#ffffff",
    red: 'rgb(236,64,122)',
    blue: 'rgb(73,163,241)',
    green: 'rgb(102,187,106)',
    pointGreen: 'rgb(76, 175, 80)',
    // sky: "#b7e1ff",
    // black: "black",
    // bg: "#f6f8fa",
    // display: "#1e232b",
    // head: "#3c4051",
    // txt: "#666f7e",
    // subtxt: "#949ba8",
    // cancel: "#c8cdd0",
    // line: "#d9e0e1",
    warn: "#ff4747",
    disabled: '#E5E5E5',
};

export const iconSize = {
    big: '2.5rem',
    medium: '1.3rem',
    small: '1.2rem',
}

export const defaultSize = {
    desktop: {
        inputHeight: "3.75rem",
        inputPadding: "1.25rem",
        buttonHeight: "5rem",
        container: "85.25rem",
        cardWidth: "19.5rem",
        selectCardWidth: "26.75rem",
        storyWidth: "15.75rem",
        iconButtonHeight: "3rem",
        iconButtonWidth: "3rem",
    },
    mobile: {
        inputHeight: "3rem",
        inputPadding: "0.875rem",
        buttonHeight: "3.5rem",
        container: "29.75rem",
        cardWidth: "10rem",
        selectCardWidth: "12.5rem",
        storyWidth: "10rem",
    },
};