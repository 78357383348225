import { selector } from 'recoil'
import { sideNavHide, currentPath, deviceIdState, devicesState, } from './atom'



export const sideNavGetHide = selector({
    key: 'sideNavGetHide',
    get: ({ get }) => {
        const isHide = get(sideNavHide);
        return isHide
    }
})

export const currentGetPath = selector({
    key: 'currentGetPath',
    get: ({ get }) => {
        const isPath = get(currentPath);
        return isPath
    }
})

export const deviceIdGetState = selector({
    key: 'deviceIdGetState',
    get: ({ get }) => {
        const deviceId = get(deviceIdState)
        return deviceId
    }
})

export const devicesGetState = selector({
    key: 'devicesGetState',
    get: ({ get }) => {
        const devices = get(devicesState)
        return devices
    }
})
