import { colorPalette, desktopFontSize, iconSize } from "../../config/palette";

const props = {
  titleLogoProps: {
    marginTop: "1.2rem",
    titleProps: {
      children: "ODN Dashboard",
      fontSize: desktopFontSize.sm2,
      color: colorPalette.white,
    },
  },
  menuListsProps: {
    menuListProps: [
      {
        href: "/",
        subTitleProps: {
          children: "대시보드",
          fontSize: desktopFontSize.sm2,
          fontWeight: 500,
          color: colorPalette.white,
        },
        iconProps: {
          iconName: "MdDashboard",
          cursor: "default",
          size: iconSize.small,
          color: colorPalette.white,
        },
      },
      {
        href: "/map",
        subTitleProps: {
          children: "지도",
          fontSize: desktopFontSize.sm2,
          fontWeight: 500,
          color: colorPalette.white,
        },
        iconProps: {
          iconName: "MdMap",
          cursor: "default",
          size: iconSize.small,
          color: colorPalette.white,
        },
      },
      {
        href: "/tables",
        subTitleProps: {
          children: "데이터 테이블",
          fontSize: desktopFontSize.sm2,
          fontWeight: 500,
          color: colorPalette.white,
        },
        iconProps: {
          iconName: "MdOutlineTableView",
          cursor: "default",
          size: iconSize.small,
          color: colorPalette.white,
        },
      },
      // , {
      //     href: '/notifications',
      //     subTitleProps: {
      //         children: '알림',
      //         fontSize: desktopFontSize.sm2,
      //         fontWeight: 500,
      //         color: colorPalette.white
      //     },
      //     iconProps: {
      //         iconName: 'MdNotificationsActive',
      //         cursor: 'default',
      //         size: iconSize.small,
      //         color: colorPalette.white
      //     }
      // }, {
      //     href: '/profile',
      //     subTitleProps: {
      //         children: '프로필',
      //         fontSize: desktopFontSize.sm2,
      //         fontWeight: 500,
      //         color: colorPalette.white
      //     },
      //     iconProps: {
      //         iconName: 'MdPerson',
      //         cursor: 'default',
      //         size: iconSize.small,
      //         color: colorPalette.white
      //     }
      // }, {
      //     href: '/authentication/sign-in',
      //     subTitleProps: {
      //         children: '로그인',
      //         fontSize: desktopFontSize.sm2,
      //         fontWeight: 500,
      //         color: colorPalette.white
      //     },
      //     iconProps: {
      //         iconName: 'MdLogin',
      //         cursor: 'default',
      //         size: iconSize.small,
      //         color: colorPalette.white
      //     }
      // }, {
      //     href: '/authentication/sign-up',
      //     subTitleProps: {
      //         children: '회원가입',
      //         fontSize: desktopFontSize.sm2,
      //         fontWeight: 500,
      //         color: colorPalette.white
      //     },
      //     iconProps: {
      //         iconName: 'MdOutlineTextSnippet',
      //         cursor: 'default',
      //         size: iconSize.small,
      //         color: colorPalette.white
      //     }
      // }
    ],
  },
};
export default props;
