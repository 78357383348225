import {
  iconSize,
  colorGradient,
  dividerGradient,
  colorPalette,
  desktopFontSize,
} from "../../config/palette";
import { marker } from "../../config/images";

const props = {
  chart: [
    {
      type: "chart",
      chart: {
        type: "line",
      },
      title: {
        text: "온도",
        align: "left",
      },
    },
    {
      type: "chart",
      chart: {
        type: "line",
      },
      title: {
        text: "용존산소",
        align: "left",
      },
    },
    {
      type: "chart",
      chart: {
        type: "line",
      },
      title: {
        text: "염도",
        align: "left",
      },
    },
    {
      type: "chart",
      chart: {
        type: "line",
      },
      title: {
        text: "산성도",
        align: "left",
      },
    },
  ],
  measure: [
    {
      type: "measure",
      iconContents: {
        icon: {
          bgColor: colorGradient.red.color,
          boxShadow: colorGradient.red.shadow,
          iconProps: {
            iconName: "MdOutlineThermostat",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "24.3",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "수온",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
          unit: {
            children: "℃",
            fontSize: desktopFontSize.xs,
          },
        },
      },
      divider: {
        bgImg: dividerGradient.gray,
      },
      change: {
        date: {
          children: "48시간 전보다",
          fontSize: desktopFontSize.sm1,
          fontWeight: 300,
          color: colorPalette.secondary,
        },
        amount: {
          children: "+55%",
          fontSize: desktopFontSize.sm2,
          fontWeight: "bold",
          color: colorPalette.green,
        },
      },
    },
    {
      type: "measure",
      iconContents: {
        icon: {
          bgColor: colorGradient.blue.color,
          boxShadow: colorGradient.blue.shadow,
          iconProps: {
            iconName: "MdOutlineWaterDrop",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "9.8",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "용존 산소",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
          unit: {
            children: "mg/l",
            fontSize: desktopFontSize.xs,
          },
        },
      },
      divider: {
        bgImg: dividerGradient.gray,
      },
      change: {
        date: {
          children: "48시간 전보다",
          fontSize: desktopFontSize.sm1,
          fontWeight: 300,
          color: colorPalette.secondary,
        },
        amount: {
          children: "+55%",
          fontSize: desktopFontSize.sm2,
          fontWeight: "bold",
          color: colorPalette.green,
        },
      },
    },
    {
      type: "measure",
      iconContents: {
        icon: {
          bgColor: colorGradient.green.color,
          boxShadow: colorGradient.green.shadow,
          iconProps: {
            iconName: "MdOutlineBloodtype",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "8.1",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "수소이온농도",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
          unit: {
            children: "pH",
            fontSize: desktopFontSize.xs,
          },
        },
      },

      divider: {
        bgImg: dividerGradient.gray,
      },
      change: {
        date: {
          children: "48시간 전보다",
          fontSize: desktopFontSize.sm1,
          fontWeight: 300,
          color: colorPalette.secondary,
        },
        amount: {
          children: "+55%",
          fontSize: desktopFontSize.sm2,
          fontWeight: "bold",
          color: colorPalette.green,
        },
      },
    },
    {
      type: "measure",
      iconContents: {
        icon: {
          bgColor: colorGradient.gray.color,
          boxShadow: colorGradient.gray.shadow,
          iconProps: {
            iconName: "MdGrain",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "32.3",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "염도",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
          unit: {
            children: "ppm",
            fontSize: desktopFontSize.xs,
          },
        },
      },

      divider: {
        bgImg: dividerGradient.gray,
      },
      change: {
        date: {
          children: "48시간 전보다",
          fontSize: desktopFontSize.sm1,
          fontWeight: 300,
          color: colorPalette.secondary,
        },
        amount: {
          children: "+55%",
          fontSize: desktopFontSize.sm2,
          fontWeight: "bold",
          color: colorPalette.green,
        },
      },
    },
  ],
  battery: [
    {
      type: "battery",
      chart: {
        type: "donut",
        series: [28, 72],
      },
      iconContents: {
        icon: {
          bgColor: colorGradient.yellow.color,
          boxShadow: colorGradient.yellow.shadow,
          iconProps: {
            iconName: "MdBolt",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "12.5",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "전압",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
          unit: {
            children: "V",
            fontSize: desktopFontSize.xs,
          },
        },
      },
      divider: {
        bgImg: dividerGradient.gray,
      },
    },
  ],
  device: [
    {
      type: "device",
      iconContents: {
        icon: {
          bgColor: colorGradient.indigo.color,
          boxShadow: colorGradient.indigo.shadow,
          iconProps: {
            iconName: "MdOutlineMemory",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "스마트 부표",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "타입",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
        },
      },
      divider: {
        bgImg: dividerGradient.gray,
      },
      textContents: {
        operate_time: {
          title: {
            children: "누적 가동중",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
            color: colorPalette.secondary,
          },
          value: {
            children: "122일",
            fontSize: desktopFontSize.sm2,
            fontWeight: "bold",
            color: colorPalette.primary,
          },
        },
        operate_status: {
          operate: {
            children: "가동중",
            fontSize: desktopFontSize.sm1,
            fontWeight: "bold",
            color: colorPalette.green,
          },
          stop: {
            children: "중지됨",
            fontSize: desktopFontSize.sm1,
            fontWeight: "bold",
            color: colorPalette.disabled,
          },
        },
        device_info: {
          serial: "SN-TRIPA-",
          owner: "ODN",
          depth: "중층(15m)",
        },
      },
    },
  ],
  map: [
    {
      type: "map",
      location: {
        lat: 34.897121,
        lng: 127.58083,
      },
      zoom: 13,
      marker: marker,
      mapOptions: {
        minZoom: 9, //9
        maxZoom: 14,
        zoomControl: false,
      },
      iconContents: {
        icon: {
          bgColor: colorGradient.dark_green.color,
          boxShadow: colorGradient.dark_green.shadow,
          iconProps: {
            iconName: "MdExplore",
            cursor: "default",
            size: iconSize.big,
            color: colorPalette.white,
          },
        },
        contents: {
          value: {
            children: "전라남도 금당면 가학리",
            fontSize: desktopFontSize.xl2,
            color: colorPalette.primary,
          },
          category: {
            children: "위치",
            fontSize: desktopFontSize.sm1,
            fontWeight: 300,
          },
        },
      },
      divider: {
        bgImg: dividerGradient.gray,
      },
    },
  ],
};
export default props;
