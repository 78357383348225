// import { AiOutlineConsoleSql } from "react-icons/ai"
import { iconSize, colorPalette, desktopFontSize } from "../../config/palette"


const props = {
    routeTitleProps: {
        routeProps: {
            iconProps: {
                iconName: 'MdHome',
                size: iconSize.small,
                cursor: 'pointer',
                color: colorPalette.iconDefault,
            },
            subTitleProps: {
                children: 'Dashboard',
                color: colorPalette.primary,
                fontSize: desktopFontSize.sm1,
                padding: '0',
            }
        },

        titleProps: {
            children: 'Dashboard',
            color: colorPalette.primary,
        }
    },
    inputSetProps: {
        iconProps: {
            icons: [
                {
                    iconName: 'MdAccountCircle',
                    size: iconSize.medium,
                    color: colorPalette.secondary,
                    cursor: 'pointer',
                    padding: '0 0.4rem',
                    onClick: () => console.log('account')
                }, {
                    iconName: 'MdSettings',
                    size: iconSize.medium,
                    color: colorPalette.secondary,
                    cursor: 'pointer',
                    padding: '0 0.4rem',
                    onClick: () => console.log('setting')
                }, {
                    alias: 'menu_icon',
                    iconName: 'MdMenuOpen',
                    size: '1.5rem',
                    color: colorPalette.secondary,
                    cursor: 'pointer',
                    padding: '0 0.4rem',
                    onClick: () => console.log('menu_open')
                }, {
                    alias: 'menu_icon',
                    iconName: 'MdMenu',
                    size: '1.5rem',
                    color: colorPalette.secondary,
                    cursor: 'pointer',
                    padding: '0 0.4rem',
                    onClick: () => console.log('menu_close')
                }, {
                    iconName: 'MdNotifications',
                    size: iconSize.medium,
                    color: colorPalette.secondary,
                    cursor: 'pointer',
                    padding: '0 0.4rem',
                    onClick: () => console.log('notifications')
                }
            ],
        },
        searchProps: {
            width: '12rem',
            placeholder: 'Select Device'
        }
    }
}

export default props;