import React from 'react'
import Chart from "react-apexcharts";

const inititalTitle = {
    text: 'line chart',
    align: 'left'
}

const Line = ({ series, xaxis, title, forecastDataPoints, ...option }) => {
    var options = {
        chart: {
            id: 'basic',
            type: 'line',
        },
        title: title ? title : inititalTitle,
        xaxis,
        forecastDataPoints: forecastDataPoints ? forecastDataPoints : {},
        ...option
    }
    console.log(forecastDataPoints)
    return (
        <Chart options={options} series={series} type='line' />
    )
}

const Bar = ({ series, xaxis }) => {
    var options = {
        chart: {
            id: 'basic',
            type: 'line',
        },
        xaxis

    }
    return <Chart options={options} series={series} type='bar' />
}


const Donut = ({ series, xaxis }) => {
    const options = {
        chart: {
            type: 'donut'
        },
        labels: ["소비", '잔량'],
        colors: ["#adb5bd", "#FBB034"],
        legend: {
            position: 'top'
        },
        stroke: {
            width: 8
        },
        plotOptions: {
            pie: {
                size: 200,
                donut: {
                    size: '40%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            color: 'rgb(52,71,103)',
                            offsetY: 0,
                        },
                        value: {
                            fontSize: '1rem',
                            show: true,
                            color: 'black',
                        },
                    },
                },
            },
        },

        xaxis: {
            type: 'numeric'
        }
    }
    return <Chart style={{ display: 'flex', justifyContent: 'center' }} width='220' height='320' options={options} series={series} type='donut' />
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Line: Line,
    Bar: Bar,
    Donut: Donut
}