import React from "react";
//import GoogleMapReact from "google-map-react";
import { GOOGLE_API_KEY } from "../../../config/api";
import GoogleMapReact from "google-map-react";
import * as S from "./style";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { deviceIdState } from "../../../recoils/atom";

const Map = (map) => {
	const [, setId] = useRecoilState(deviceIdState);
	const location = map.location;
	// console.log(map);
	function handleClick(e) {
		//console.log("map에서 보내는 값", e.target.attributes.id.value);
		setId(e.target.attributes.id.value);
	}
	const Marker = ({ onClick, src, ...buoy }) => {
		return (
			<Link to="/">
				<S.Marker {...buoy} src={src} onClick={onClick}>
					{buoy.$hover ? (
						<React.Fragment>
							<S.MarkerPop>
								<S.Info>
									<p>부표 번호 |</p>
									<p>{`no.${buoy.id}`}</p>
								</S.Info>
								<S.Info>
									<p>부표 종류 |</p>
									<p>{buoy.type}</p>
								</S.Info>
								<S.Info>
									<p>부표 위치 |</p>
									<p>{buoy.address}</p>
								</S.Info>
							</S.MarkerPop>
						</React.Fragment>
					) : null}
				</S.Marker>
			</Link>
		);
	};
	return (
		<S.Container>
			<GoogleMapReact
				bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
				defaultCenter={location}
				defaultZoom={map.zoom}
				options={map.mapOptions}
			>
				{map.buoyLocations ? (
					map.buoyLocations.map((buoy) => (
						<Marker {...buoy} src={map.marker} onClick={handleClick} />
					))
				) : (
					<S.Marker {...location} src={map.marker} />
				)}
			</GoogleMapReact>
		</S.Container>
	);
};

export default Map;
