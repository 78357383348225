import React from "react";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';



const SearchInput = ({ placeholder, width, onChange, data = [], value }) => {
    return <Autocomplete
        value={value}
        isOptionEqualToValue={(data, value) => data.label === value}
        size={'small'}
        id={data.id}
        options={data}
        sx={{ width: { width }, marginRight: 1 }}
        onChange={onChange}
        renderInput={
            (params) => <TextField {...params} defaultValue={data[0]} label={placeholder} />
        } />
}
export default SearchInput


