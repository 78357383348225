
export const dataProps = {
    card: {
        type: 'chart',
        chart: {
            type: 'line'
        },
    },
    table: {
        options: {},
        columns: [],
        scroll: {
            x: '1200px'
        }
    },
    categories: [
        { label: '산소', value: 'oxygen' },
        { label: '염도', value: 'conduct' },
        { label: 'PH', value: 'ph' },
    ]
}