import React from "react";
import styled from "styled-components";
import { Logo, Title } from '../../atoms'




const StyledTitleLogo = styled.div`
    display:flex;
    justify-content: center;
    margin-top:${props => props.marginTop};
`



const TitleLogo = ({ titleProps, marginTop }) => {
    const needProps = {
        marginTop
    }
    return (
        <StyledTitleLogo {...needProps}>
            <Logo margin={'0 -0.3rem 1.3rem 0'} />
            <Title {...titleProps} />
        </StyledTitleLogo>
    );
};

export default TitleLogo