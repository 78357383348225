import { Radio, Table as AntdTable } from 'antd'
import React from 'react'
import { ChartCard } from '../../molecules'


const Table = ({ card, table = {}, setCategory, categories, category }) => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ChartCard chart={card} />
            </div>
            <Radio.Group value={category} style={{ margin: '1rem 0' }} optionType='button' options={categories} onChange={(e) => { setCategory(e.target.value) }} />
            <AntdTable {...table} />
        </React.Fragment>
    )
}

export default Table