import React from "react";
import styled from "styled-components";
import { Text, Icon } from "../../atoms";
import { viewSize, colorPalette } from "../../../config/palette";

const StyledLinkInfo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 1rem;
`;

const StyledSNS = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const StyledMadeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  @media (max-width: ${viewSize.mobile}) {
    flex-direction: column-reverse;
  }
`;

const Link = styled.a`
  text-decoration: inherit;
`;

const Footer = ({ madeText, companyName, linkText, icons = [] }) => {
  return (
    <StyledFooter>
      <div>
        <Text {...madeText.start} />
        <Text {...companyName.first} />
        <Text {...companyName.second} />
        <Text {...companyName.third} />
        <Text {...madeText.end} />
      </div>
      <StyledMadeInfo>
        <StyledLinkInfo>
          <div>
            <Link href={linkText.about.url} target="_blank">
              <Text {...linkText.about} />
            </Link>
            <span
              style={{ padding: "0 0.3rem", color: colorPalette.secondary }}
            >
              |
            </span>
            <Link href={linkText.blog.url} target="_blank">
              <Text {...linkText.blog} />
            </Link>
          </div>
          <StyledSNS>
            {icons.map((icon, index) => (
              <Link href={icon.url} target="_blank">
                <Icon key={index} {...icon} />
              </Link>
            ))}
          </StyledSNS>
        </StyledLinkInfo>
      </StyledMadeInfo>
    </StyledFooter>
  );
};
export default Footer;
