import { Card, Divider, Chart } from '../../atoms'
import { CardHeader } from '..'


const BatteryCard = ({ chart, divider, iconContents, ...card }) => {
    return (
        <Card {...card} >
            <CardHeader {...iconContents} />
            <Divider {...divider} />
            <Chart.Donut {...chart} />

        </Card >
    )
}
export default BatteryCard