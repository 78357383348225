import {colorPalette} from '../config/palette'

export const mappingData = ({ state, datas = [] }) => {
	state.iconContents.contents.value.children = datas[datas.length - 1].toFixed(2);
	let delta = datas[datas.length - 1] - datas[0];
	let prefix = delta >= 0 ? "+" : "";
	let amount_text = `${prefix}${((delta / datas[0]) * 100).toFixed(2)}%`;
	state.change.amount.children = amount_text;
	state.change.amount.color =
		delta >= 0 ? colorPalette.green : colorPalette.warn;
};

export const keyMappingData = ({state, datas=[], key}) => {
	let lastDataIndex = datas.length-1 // 지난 데이터
	let newDataIndex = 0 // 최신 데이터 
	state.iconContents.contents.value.children = datas[newDataIndex][key].toFixed(2);
	let delta = datas[newDataIndex][key] - datas[lastDataIndex][key];
	let prefix = delta >= 0 ? "+" : "";
	let amount_text = `${prefix}${((delta / datas[lastDataIndex][key]) * 100).toFixed(2)}%`;
	state.change.amount.children = amount_text;
	state.change.amount.color =
		delta >= 0 ? colorPalette.green : colorPalette.warn;
}