import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { getDevices } from "../../api/device";
import { TopNav as TopNavTemplate } from "../../components/template";
import { deviceIdState, devicesState } from "../../recoils/atom";
import { currentGetPath } from "../../recoils/selectors";
import topNavProps from "./data";

const TopNav = () => {
  const [id, setId] = useRecoilState(deviceIdState);
  const [devices, setDevices] = useRecoilState(devicesState);
  const path = useRecoilValue(currentGetPath);
  const pathname = path
    .split("/")
    .pop()
    .replace(/^[a-z]/, (char) => char.toUpperCase());

  useEffect(() => {
    (async () => {
      let res = await getDevices();
      let res_device = res.data.results.map((device) => {
        return {
          id: device.device_id,
          device_type: device.device_type,
          serial_number: device.serial_number,
          label: `${device.serial_number}${device.device_id}`,
        };
      });
      setDevices(res_device);
      setId(12);
    })();
  }, [setDevices, setId]);

  const findMapByKey = (i, items = []) => {
    let returnData;
    items.forEach((item) => {
      if (item.id === i) {
        returnData = item;
      }
    });
    return returnData ? returnData.label : "";
  };
  topNavProps.inputSetProps.searchProps.data = devices;
  topNavProps.inputSetProps.searchProps.onChange = (e, value) => {
    setId(value.id);
  };
  topNavProps.inputSetProps.searchProps.value = findMapByKey(id, devices);
  topNavProps.routeTitleProps.routeProps.subTitleProps.children =
    pathname === "" ? "Dashboard" : pathname;
  topNavProps.routeTitleProps.titleProps.children =
    pathname === "" ? "Dashboard" : pathname;
  return <TopNavTemplate {...topNavProps} />;
};

export default TopNav;
