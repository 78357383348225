import React from "react";
import { Cards } from "../../organisms";
import styled from "styled-components"
import { viewSize } from "../../../config/palette"

const StyledCardBox = styled.div`
	width: 100%;

	@media (min-width: ${viewSize.mobile}){
		display: flex;
		align-items: center;
	}
`;

const Dashboard = ({ measure, chart, device, map, battery }) => {
	return (
		<React.Fragment>
			<StyledCardBox>
				<Cards cards={device} />
				<Cards cards={battery} />
				<Cards cards={map} />
			</StyledCardBox>
			<Cards cards={measure} />
			<Cards cards={chart} wrap={true} />
		</React.Fragment>
	);
};
export default Dashboard;
