import React from "react";
import styled, { css } from "styled-components";
import { useRecoilValue } from "recoil";
import { currentGetPath } from '../../../recoils/selectors';
import { colorGradient } from '../../../config/palette'
import { Icon, SubTitle } from '../../atoms'


const Menu = styled.div`
    color: #ffffff;
    background:transparent;
    box-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    border-radius: 0.375rem;
    padding: 0 1rem;
    margin: 0.1rem 0;
    transition: 0.3s;
    ${(props) =>
        props.path ?
            css`
            background: ${colorGradient.blue.color};
        `:
            css`
        &: hover{
            background-color: gray;
        `

    };
`;


const StyledMenuListLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    width: 100%;
`;

const StyledMenuList = styled.li`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    list-style: none;
`;


const MenuList = ({ href, iconProps, subTitleProps }) => {
    const isPath = useRecoilValue(currentGetPath)
    const path = href === isPath

    return (
        <StyledMenuListLink href={href}>
            <StyledMenuList>
                <Menu path={path}>
                    <Icon  {...iconProps} />
                    <SubTitle {...subTitleProps} />
                </Menu>
            </StyledMenuList>
        </StyledMenuListLink>
    );
};

export default MenuList;