import React from "react";

import styled from "styled-components";

const StyledText = styled.span`
  margin: 0px;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  padding: ${(props) => props.padding};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
`;

const Text = ({ children, fontSize, fontWeight, color, padding = 0 }) => {
  const needProps = {
    color,
    fontSize,
    fontWeight,
    padding,
  };
  return <StyledText {...needProps}>{children}</StyledText>;
};

export default Text;
