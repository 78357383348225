import React from "react";
import styled from "styled-components";
import { MenuList } from '../../molecules'



const StyledMenuLists = styled.ul` 
    width: 100%;
    list-style: none;
    padding: 0;
`;


const MenuLists = ({ menuListProps }) => {
    return (
        <StyledMenuLists>
            {menuListProps.map((i, index) => <MenuList key={index} {...i} />)}
        </StyledMenuLists>
    );
};

export default MenuLists