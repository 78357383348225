import React from "react";
import styled from "styled-components";


const StyledUnitText = styled.div`
	display: flex;
    justify-content:center;
	align-items: center;
	font-weight: bold;
    padding: 0.1rem 0 0.1rem 0.2rem;
	color: ${props => props.color};
    font-size: ${props => props.fontSize};
`;


const Span = styled.span`
    width: 100%;
    
`;

const UnitText = ({ children, fontSize, color = 'inherit' }) => {
    const needProps = {
        color,
        fontSize,
    };
    return (
        <StyledUnitText {...needProps}>
            <Span>
                {children}
            </Span>
        </StyledUnitText>
    );
};

export default UnitText;