import React from "react";
import styled from "styled-components";
import { ChartCard, MeasureCard, MapCard, DeviceCard, BatteryCard } from "../../molecules";
import { viewSize } from "../../../config/palette";

const StyledCards = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => (props.justify ? props.justify : "center")};
	align-items: center;
	margin-top: 1rem;
	width: 100%;
	${(props) => props.wrap && "flex-wrap : wrap;"}

	@media(min-width: ${viewSize.mobile}) {
		flex-direction: row;
	}
`;

const CardGenerate = (card) => {
	switch (card.type) {
		case "chart":
			return <ChartCard chart={card} />;
		case "measure":
			return <MeasureCard {...card} />;
		case "map":
			return <MapCard {...card} height="21.6rem" />;
		case "device":
			return <DeviceCard {...card} height="21.6rem" />;
		case "battery":
			return <BatteryCard {...card} />;
		default:
			return <></>;
	}
};

const Cards = ({ cards = [], wrap = false, justify }) => {
	return (
		<StyledCards wrap={wrap} justify={justify}>
			{cards.map((card, index) => {
				return <CardGenerate key={index} {...card} />;
			})}
		</StyledCards>
	);
};

export default Cards;
