import React from "react";
import styled from "styled-components";



const StyledDivider = styled.hr`
	border: 0.0625rem solid rgba(0, 0, 0, 0.08);
    background-color: transparent;
    opacity: 0.25;
    flex-shrink: 0;
    border-width: 0;
    height: 0.0625rem;
    border-bottom: none;
    margin: 1rem 0;
    background-image: ${props => props.bgImg};
`;


const Divider = ({ bgImg = 'linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0))' }) => {
    const needProps = {
        bgImg
    }

    return <StyledDivider {...needProps} />
};

export default Divider