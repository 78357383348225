import React from 'react'
import styled from "styled-components"
import { viewSize } from '../../../config/palette';

const Container = styled.div`
    margin: 1rem;
    width : ${props => props.width ? props.width : '99%'};
    height : ${props => props.height ? props.height : 'auto'};
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: ${props => props.justify ? props.justify : 'none'};
    align-items: ${props => props.align ? props.align : 'none'};
    flex-direction: ${props => props.flexDirect ? props.flexDirect : 'column'};
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
    @media screen and (max-width : ${viewSize.mobile}){
        width : 98%;
    }
`;

const Card = ({ children, width, height, flexDirect, justify, align }) => {
    const needProps = {
        width,
        height,
        flexDirect,
        justify,
        align
    }
    return <Container {...needProps}>
        {children}
    </Container>
}

export default Card