import React from "react";
import styled from "styled-components";

const StyledDescription = styled.span`
    margin: 0px;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    padding: 0.5rem;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    font-weight: ${props => props.fontWeight};
	color: ${props => props.color};
    font-size: ${props => props.fontSize};
`;



const Description = ({ children, fontSize, fontWeight, color }) => {
    const needProps = {
        color,
        fontSize,
        fontWeight,
    };
    return (
        <StyledDescription {...needProps}>
            {children}
        </StyledDescription>
    );
};

export default Description