import { Card, Text, Divider, PointText } from '../../atoms'
import { CardHeader } from '../'
import styled from 'styled-components'

const StyledDescription = styled.div`
    padding: 0 1rem 1rem 1rem;
`


const MeasureCard = ({ iconContents, change, divider }) => {
    return (
        <Card>
            <CardHeader {...iconContents} />
            <Divider {...divider} />
            <StyledDescription>
                <PointText {...change.amount} /><Text {...change.date}></Text>
            </StyledDescription>
        </Card>
    )
}

export default MeasureCard