const measureMap = {
  temperature: "temperature",
  oxygen: "oxygen_mpl",
  conduct: "salinity",
  ph: "ph",
};

const covertMap = {
  oxygen_per: "용존 산소(%)",
  oxygen_mpl: "용존 산소(mg/l)",
  oxygen_ppm: "용존 산소(ppm)",
  conductivity: "전기전도도(μS/cm)",
  salinity: "염도(mV)",
  tds: "총용존 고형물(ppm)",
  ph: "수소이온농도(ph)",
  redox: "산화환원반응(mV)",
  ph_meter: "수소이온농도(mv)",
  temperature: "온도(℃)",
  serial_number: "시리얼",
  measured_time: "측정시간",
};

const deviceTypes = {
  mounted: "포터블",
  buoy: "스마트 부표",
  platform: "해양 플랫폼",
};

export const parserLocation = (datas) => {
  // console.log("datas", datas);
  let location;
  let address;

  if (datas.data.results.length !== 0) {
    location = {
      lat: datas.data.results[0].coordinate[0],
      lng: datas.data.results[0].coordinate[1],
    };
    address = datas.data.results[0].full_address.replace("None", "");
  }
  return {
    location,
    address,
  };
};

export const measureToChartData = (measures = [], category) => {
  const labels = [];
  const data = [];
  let temp_data = [];
  let newMeasures = [...measures];

  while (newMeasures.length !== 0) {
    let measure = newMeasures.pop();
    let time = measure.measured_time.split(":");
    temp_data.push(measure[measureMap[category]]);

    if (temp_data.length === 5 || newMeasures.length === 0) {
      let mean = temp_data.reduce((p, c) => p + c, 0) / temp_data.length;
      data.push(mean);
      temp_data = [];
      labels.push(time[0].split("T")[1]);
    }
  }
  return {
    labels,
    datasets: [
      {
        data,
        label: category,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

export const measureToAspectChartData = (measures = [], category) => {
  const labels = [];
  const data = [];
  let temp_data = [];
  let newMeasures = [...measures];

  while (newMeasures.length !== 0) {
    let measure = newMeasures.pop();
    let time = measure.measured_time.split(":");
    temp_data.push(measure[measureMap[category]]);

    if (temp_data.length === 6 || newMeasures.length === 0) {
      let mean = temp_data.reduce((p, c) => p + c, 0) / temp_data.length;
      data.push(mean.toFixed(2));
      temp_data = [];
      labels.push(time[0].split("T")[1]);
    }
  }
  return {
    series: [
      {
        name: measureMap[category],
        data: data,
      },
    ],
    xaxis: {
      categories: labels,
      tickAmount: 12,
    },
  };
};

export const measureToTableData = (measures) => {
  let columns = [];

  // 컬럼 추출
  for (let [key, value] of Object.entries(measures[0])) {
    if (typeof value === "string" || typeof value === "number") {
      columns.push({
        title: covertMap[key],
        dataIndex: key,
        key: key,
        align: "center",
      });
    }
  }

  return {
    columns,
    dataSource: measures,
    scroll: {
      x: 1200,
    },
  };
};

export const devicesData = (device) => {
  const cumulative_time =
    new Date().getTime() - new Date(device.first_run_time).getTime();

  const cumulative_date = Math.floor(
    Math.abs(cumulative_time / (1000 * 60 * 60 * 24))
  );

  const device_code = device.serial_number + device.device_id;
  const owner = device.owner;
  const operate = device.operating_state ? "가동중" : "중지됨";
  const series = [100 - device.battery, device.battery];
  const type = deviceTypes[device.device_type];

  return {
    device_code,
    owner,
    operate,
    series,
    type,
    cumulative_date,
  };
};

export const makePredictData = (datas = []) => {
  let date = [];
  let doxy = [];
  let temp = [];

  datas.forEach((data, idx) => {
    if (idx % 3 === 0) {
      doxy.push(data.oxygen_mpl);
      date.push(data.measured_time.replace("T", " "));
      temp.push(data.temperature);
    }
  });

  return {
    doxy,
    date,
    temp,
  };
};

export const parserPredictData = (datas = [], startHour) => {
  let series = [];
  let temps = [];
  let labels = [];

  for (let i = 1; i <= 24; i++) {
    let hour = startHour + i;
    if (hour >= 24) {
      hour -= 24;
    }

    labels.push(hour);
  }
  datas.forEach((data, index) => {
    temps.push(data);
    if (index % 2 === 1) {
      let mean = temps.reduce((p, c) => p + c, 0) / temps.length;
      series.push(mean);
      temps = [];
    }
  });

  return {
    labels,
    series,
  };
};
