import React from "react";
import styled from "styled-components";
import { Icon, SubTitle } from '../../atoms';




const StyledSlash = styled.span`
    color: rgb(123, 128, 154);
    font-weight: bold;
    padding: 0 0.7rem;
`


const StyledRoute = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 768px){
        padding-bottom: 0.5rem;
    }
`;



const Route = ({ iconProps, subTitleProps }) => {
    return (
        <StyledRoute>
            <Icon {...iconProps} />
            <StyledSlash >/</StyledSlash>
            <SubTitle {...subTitleProps} />
        </StyledRoute>
    )

}


export default Route