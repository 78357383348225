import React, { useEffect, useState } from "react";
import { Dashboard as DashboardTemplate } from "../../components/template";
import dashboardProps from "./data";
import {
  getConducts,
  getOxygens,
  getPhs,
  getLocations,
  getDevice,
} from "../../api/measure";
import {
  measureToAspectChartData,
  parserLocation,
  devicesData,
  // makePredictData,
  // parserPredictData,
} from "../../util/parser";
import { Spin } from "antd";
import { useRecoilValue, useRecoilState } from "recoil";
import { currentPath } from "../../recoils/atom";
import { deviceIdGetState } from "../../recoils/selectors";
// import { forecastTemperature } from "../../util/forecast";
import { keyMappingData } from "../../util/mapper";
// import { getPredictOxy, getPredictTemp } from "../../api/predict";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const id = useRecoilValue(deviceIdGetState);
  const [, setPath] = useRecoilState(currentPath);
  useEffect(() => {
    if (id !== null) {
      (async () => {
        setIsLoading(true);
        let { data: device } = await getDevice({ id });
        let oxygen_res = await getOxygens({ id });
        let conduct_res = await getConducts({ id });
        let ph_res = await getPhs({ id });
        let locations = await getLocations({ id });

        // 에측 데이터
        // let predictData = makePredictData(oxygen_res.data.results)
        // let predictTempRes = await getPredictTemp({...predictData})
        // let predictOxyRes = await getPredictOxy({...predictData})

        let device_data = devicesData(device);
        dashboardProps.device[0].iconContents.contents.value.children =
          device_data.type;
        dashboardProps.device[0].textContents.operate_status.operate.children =
          device_data.operate;
        dashboardProps.device[0].textContents.operate_time.value.children =
          device_data.cumulative_date + "일";
        dashboardProps.device[0].textContents.device_info.serial =
          device_data.device_code;
        dashboardProps.device[0].textContents.device_info.owner =
          device_data.owner;
        dashboardProps.battery[0].chart.series = device_data.series;

        let { location, address } = parserLocation(locations);
        dashboardProps.map[0].location = location;
        dashboardProps.map[0].iconContents.contents.value.children = address;
        dashboardProps.chart[0] = {
          ...dashboardProps.chart[0],
          ...measureToAspectChartData(oxygen_res.data.results, "temperature"),
        };

        // 에측 데이터
        // let predictParsedData = parserPredictData(
        // 	predictTempRes.data.result[0],
        // 	parseInt(dashboardProps.chart[0].xaxis.categories[dashboardProps.chart[0].xaxis.categories.length - 1])
        // )

        // let predictParsedOxygen = parserPredictData(
        // 	predictOxyRes.data.result[0],
        // 	parseInt(dashboardProps.chart[0].xaxis.categories[dashboardProps.chart[0].xaxis.categories.length - 1])
        // )

        dashboardProps.chart[1] = {
          ...dashboardProps.chart[1],
          ...measureToAspectChartData(oxygen_res.data.results, "oxygen"),
        };

        // let temp_label = sessionStorage.getItem("temp_labels");
        // let temp_data = sessionStorage.getItem("temp_data");
        // let forecastData;
        // let forecastOxygenData;

        // if (temp_label) {
        //   forecastData = {
        //     labels: temp_label.split(","),
        //     series: {
        //       data: temp_data.split(","),
        //     },
        //   };
        // } else {
        //   forecastData = forecastTemperature({
        //     startTime:
        //       dashboardProps.chart[0].xaxis.categories[
        //         dashboardProps.chart[0].xaxis.categories.length - 1
        //       ],
        //     data: dashboardProps.chart[0].series[0].data,
        //     category: "온도",
        //     max: 0.5,
        //     min: 0,
        //     peak: 11,
        //     meanRange: [
        //       "01",
        //       "02",
        //       "03",
        //       "04",
        //       "05",
        //       "06",
        //       "15",
        //       "16",
        //       "17",
        //       "18",
        //     ],
        //     increaseRange: ["07", "08", "09", "10", "11", "12", "13", "14"],
        //     decreaseRange: ["19", "20", "21", "22", "23", "24"],
        //     peakAmount: 2,
        //   });
        //   sessionStorage.setItem("temp_labels", forecastData.labels.join(","));
        //   sessionStorage.setItem(
        //     "temp_data",
        //     forecastData.series.data.join(",")
        //   );
        // }
        // let oxygen_label = sessionStorage.getItem("oxygen_labels");
        // let oxy_data = sessionStorage.getItem("oxygen_data");
        // if (oxygen_label) {
        //   forecastOxygenData = {
        //     labels: oxygen_label.split(","),
        //     series: {
        //       data: oxy_data.split(","),
        //     },
        //   };
        // } else {
        //   forecastOxygenData = forecastTemperature({
        //     startTime:
        //       dashboardProps.chart[1].xaxis.categories[
        //         dashboardProps.chart[1].xaxis.categories.length - 1
        //       ],
        //     data: dashboardProps.chart[1].series[0].data,
        //     category: "온도",
        //     max: 0.2,
        //     min: 0,
        //     peak: 11,
        //     meanRange: [
        //       "01",
        //       "02",
        //       "03",
        //       "04",
        //       "05",
        //       "06",
        //       "15",
        //       "16",
        //       "17",
        //       "18",
        //     ],
        //     decreaseRange: ["07", "08", "11", "14"],
        //     increaseRange: [
        //       "09",
        //       "10",
        //       "19",
        //       "12",
        //       "13",
        //       "20",
        //       "21",
        //       "22",
        //       "23",
        //       "24",
        //     ],
        //     peakAmount: 2,
        //   });
        //   sessionStorage.setItem(
        //     "oxygen_labels",
        //     forecastOxygenData.labels.join(",")
        //   );
        //   sessionStorage.setItem(
        //     "oxygen_data",
        //     forecastOxygenData.series.data.join(",")
        //   );
        // }

        /*
				예측 데이터
				dashboardProps.chart[0].xaxis.categories = dashboardProps.chart[0].xaxis.categories.concat(predictParsedData.labels)
				dashboardProps.chart[0].series[0].data = dashboardProps.chart[0].series[0].data.concat(predictParsedData.series)
				dashboardProps.chart[0].forecastDataPoints = { count: 24 }

				dashboardProps.chart[1].xaxis.categories = dashboardProps.chart[1].xaxis.categories.concat(predictParsedOxygen.labels)
				dashboardProps.chart[1].series[0].data = dashboardProps.chart[1].series[0].data.concat(predictParsedOxygen.series)
				dashboardProps.chart[1].forecastDataPoints = { count: 24 }
				*/

        // dashboardProps.chart[0].xaxis.categories =
        //   dashboardProps.chart[0].xaxis.categories.concat(forecastData.labels);
        // dashboardProps.chart[0].series[0].data =
        //   dashboardProps.chart[0].series[0].data.concat(
        //     forecastData.series.data
        //   );
        // dashboardProps.chart[0].forecastDataPoints = { count: 24 };

        // dashboardProps.chart[1].xaxis.categories =
        //   dashboardProps.chart[1].xaxis.categories.concat(
        //     forecastOxygenData.labels
        //   );
        // dashboardProps.chart[1].series[0].data =
        //   dashboardProps.chart[1].series[0].data.concat(
        //     forecastOxygenData.series.data
        //   );
        // dashboardProps.chart[1].forecastDataPoints = { count: 24 };

        dashboardProps.chart[2] = {
          ...dashboardProps.chart[2],
          ...measureToAspectChartData(conduct_res.data.results, "conduct"),
        };
        dashboardProps.chart[3] = {
          ...dashboardProps.chart[3],
          ...measureToAspectChartData(ph_res.data.results, "ph"),
        };
        dashboardProps.chart[3] = {
          ...dashboardProps.chart[3],
          ...measureToAspectChartData(ph_res.data.results, "ph"),
        };

        keyMappingData({
          state: dashboardProps.measure[0],
          datas: oxygen_res.data.results,
          key: "temperature",
        });

        keyMappingData({
          state: dashboardProps.measure[1],
          datas: oxygen_res.data.results,
          key: "oxygen_mpl",
        });

        keyMappingData({
          state: dashboardProps.measure[2],
          datas: ph_res.data.results,
          key: "ph",
        });

        keyMappingData({
          state: dashboardProps.measure[3],
          datas: conduct_res.data.results,
          key: "salinity",
        });

        setIsLoading(false);
        setPath(window.location.pathname);
      })();
    }
    // console.log(dashboardProps);
  }, [id, setPath]);
  return (
    <React.Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Spin tip="데이터를 불러오는 중입니다." />
        </div>
      )}
      {!isLoading && <DashboardTemplate {...dashboardProps} />}
    </React.Fragment>
  );
};

export default Dashboard;
