import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getDevices } from "../../api/device";
import { getLocations } from "../../api/measure";
import { MapTemplate } from "../../components/template";
import { parserLocation } from "../../util/parser";
import mapProps from "./data";
const Map = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mappingData = (
    ids = [],
    types = [],
    locations = [],
    addresses = []
  ) => {
    let buoy = [];
    // eslint-disable-next-line no-new-object
    let res = new Object();

    for (let i = 0; i < ids.length; i++) {
      if (locations[i] !== undefined) {
        res = {
          id: ids[i],
          type: types[i],
          lat: locations[i].lat,
          lng: locations[i].lng,
          address: addresses[i],
        };
        buoy.push(res);
      }
    }
    return buoy;
  };
  useEffect(() => {
    // 모든 부이 location 받기
    (async () => {
      setIsLoading(true);
      // devices 전부 받아와서
      const devices = await getDevices();
      // id값 buoy값 추출해서
      const ids = devices.data.results.map((id) => id.device_id);
      const types = devices.data.results.map((id) => id.device_type);
      // id값마다 location 가져오기
      const idsLocation = await Promise.allSettled(
        ids.map((id) => getLocations({ id }))
      );

      const locations = idsLocation.map((id) => id.value);

      const coordinates = locations.map(
        (coordinate) => parserLocation(coordinate).location
      );

      const addresses = locations.map(
        (address) => parserLocation(address).address
      );

      const buoyLocations = mappingData(ids, types, coordinates, addresses);
      setIsLoading(false);
      mapProps.buoyLocations = buoyLocations;
    })();
  }, []);

  return (
    <React.Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Spin tip="데이터를 불러오는 중입니다." />
        </div>
      )}
      {!isLoading && <MapTemplate {...mapProps} />}
    </React.Fragment>
  );
};

export default Map;
