import React from "react";
import styled from "styled-components";
import { Title } from '../../atoms';
import { Route } from '../../molecules';
import { viewSize } from '../../../config/palette'



const StyledRouteTitle = styled.div`
    width: 100%;

    @media (max-width: ${viewSize.mobile}){
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row-reverse ;
        align-items: center;
        justify-content: space-between;
        padding-right: 0.5rem;
    }
    
`

const RouteTitle = ({ routeProps, titleProps }) => {
    return <StyledRouteTitle>
        <Route {...routeProps} />
        <Title {...titleProps} />
    </StyledRouteTitle>
}

export default RouteTitle