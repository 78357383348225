import { Card, SubTitle, Text, Divider, PointText } from '../../atoms'
import styled from "styled-components"
import { CardHeader } from '..'


const StyledDescription = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
`

const StyledList = styled.li`
    display: flex;
    align-items: center;
`

const StyledContents = styled.ul`
    margin: auto 0;    
`


const DeviceCard = ({ iconContents, divider, textContents, ...card }) => {

    return (
        <Card {...card}>
            <CardHeader {...iconContents} />
            <Divider {...divider} />
            <StyledContents>
                <StyledList>
                    <SubTitle color="rgb(123, 128, 154)" children='제품 코드' />
                    <Text color='rgb(52, 71, 103)' fontWeight='bold' children={textContents.device_info.serial} />
                </StyledList>
                <StyledList >
                    <SubTitle color="rgb(123, 128, 154)" children='소유자' />
                    <Text color='rgb(52, 71, 103)' fontWeight='bold' children={textContents.device_info.owner} />
                </StyledList>
                <StyledList >
                    <SubTitle color="rgb(123, 128, 154)" children='측정 수심' />
                    <Text color='rgb(52, 71, 103)' fontWeight='bold' children='중층(15m)' />
                </StyledList>
            </StyledContents>
            <Divider {...divider} />
            <StyledDescription>
                <div>
                    <PointText {...textContents.operate_time.value} />
                    <Text {...textContents.operate_time.title} />
                </div>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <PointText {...textContents.operate_status.operate} />
                    <PointText {...textContents.operate_status.stop} />
                </div>
            </StyledDescription>
        </Card>
    )
}

export default DeviceCard