import React from "react";
import Dashboard from "./pages/dashboard";
import Table from "./pages/table";
import Map from "./pages/map";
import { createBrowserRouter } from "react-router-dom";
// import Signin from "./pages/Signin";
// import Signup from "./pages/Signup";

const routes = createBrowserRouter([
  {
    key: "root",
    path: "/",
    element: <Dashboard />,
  },
  {
    key: "map",
    path: "/map",
    element: <Map />,
  },
  {
    key: "tables",
    path: "/tables",
    element: <Table />,
  },
  // {
  // 	key: "notifications",
  // 	path: "/notifications",
  // 	element: (
  // 		<div
  // 			style={{
  // 				width: "100%",
  // 				height: "80vh",
  // 				display: "flex",
  // 				justifyContent: "center",
  // 				alignItems: "center",
  // 			}}
  // 		>
  // 			<h1 style={{ fontSize: "5em" }}>Notifications</h1>
  // 		</div>
  // 	),
  // },
  // {
  // 	key: "profile",
  // 	path: "/profile",
  // 	element: (
  // 		<div
  // 			style={{
  // 				width: "100%",
  // 				height: "80vh",
  // 				display: "flex",
  // 				justifyContent: "center",
  // 				alignItems: "center",
  // 			}}
  // 		>
  // 			<h1 style={{ fontSize: "5em" }}>Profile</h1>
  // 		</div>
  // 	),
  // },
  // {
  // 	key: "signin",
  // 	path: "/authentication/sign-in",
  // 	element: (
  // 		<Signin />
  // 	),
  // },
  // {
  // 	key: "signup",
  // 	path: "/authentication/sign-up",
  // 	element: (
  // 		<Signup />
  // 	),
  // },
]);

export default routes;
