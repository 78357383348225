import defaultApi from "./default";

export const getConducts = async ({ date_time, end_date_time, id, size = 288 }) => {
    let params = {
        date_time,
        end_date_time,
        size
    }

    return await defaultApi({ params, url: `/devices/${id}/conducts/` })
}

export const getLocations = async ({ date_time, end_date_time, id }) => {
    let params = {
        date_time,
        end_date_time
    }

    return await defaultApi({ params, url: `/devices/${id}/locations/` })
}

export const getOxygens = async ({ date_time, end_date_time, id, size = 432 }) => {
    let params = {
        date_time,
        end_date_time,
        size
    }

    return await defaultApi({ params, url: `/devices/${id}/oxygens/` })
}

export const getPhs = async ({ date_time, end_date_time, id, size = 288 }) => {
    let params = {
        date_time,
        end_date_time,
        size
    }

    return await defaultApi({ params, url: `/devices/${id}/phs/` })
}

export const getChlorophylls = async ({ date_time, end_date_time, id }) => {
    let params = {
        date_time,
        end_date_time
    }

    return await defaultApi({ params, url: `/devices/${id}/phs/` })
}


export const getDevice = async ({ id }) => {
    let params = {

    }
    return await defaultApi({ params, url: `/devices/${id}/` })
}


