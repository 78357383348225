import { marker } from "../../config/images";

const props = {
	location: {
		lat: 34.709597,
		lng: 126.905018,
	},
	zoom: 9,
	mapOptions: {
		minZoom: 8,
		maxZoom: 15, //14
	},
	buoyLocations: [],
	marker: marker,
};

export default props;
