import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: sessionStorage,
});

export const sideNavHide = atom({
  key: "sideNavState",
  default: window.innerWidth <= 1200 ? true : false,
});

export const currentPath = atom({
  key: "currentPath",
  default: window.location.pathname,
});

export const deviceIdState = atom({
  key: "deviceIdState",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const devicesState = atom({
  key: "devicesState",
  default: [],
});
