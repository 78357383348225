import React from "react";
import styled, { css } from "styled-components";
import { useRecoilValue } from 'recoil';
import { sideNavGetHide } from '../../recoils/selectors';
import { RouterProvider } from 'react-router-dom'
import { viewSize } from '../../config/palette'


import routes from '../../routes'
import TopNav from '../top_nav'
import Footer from '../footer'

const StyledMain = styled.div`
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
    position: relative;
    display : flex;
    flex-direction : column;
    transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    @media (min-width: ${viewSize.mobile}){
        ${(props) =>
        !props.isHide &&
        css`
                margin-left: 17.125rem;                
            `
    };  
    }
        
`;
const Main = () => {
    const isHide = useRecoilValue(sideNavGetHide)
    return (
        <StyledMain isHide={isHide}>
            <TopNav />
            <RouterProvider router={routes} />
            <Footer />
        </StyledMain>
    )
};

export default Main