import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: inherit;
  }
  body {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    background-color: #f0f2f5;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
`;

export default GlobalStyle;
