import axios from 'axios'
import { BASE_URL } from '../config/api'

const defaultApi = async ({ params, method = 'GET', data, headers, url, noBase=false }) => {
    try {
        return await axios({
            method,
            data,
            params,
            headers,
            url: noBase ? url : BASE_URL + url
        })
    } catch (e) {
        return {}
    }

}

export default defaultApi