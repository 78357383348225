import { desktopFontSize, colorPalette } from "../../config/palette";

const props = {
  madeText: {
    start: {
      children: "© 2022 made by",
      fontSize: desktopFontSize.sm1,
      fontWeight: 600,
      color: colorPalette.secondary,
      padding: "0.25rem",
    },
    end: {
      children: "Corp.",
      fontSize: desktopFontSize.sm1,
      fontWeight: 600,
      color: colorPalette.secondary,
      padding: "0.25rem",
    },
  },
  companyName: {
    first: {
      children: "O",
      fontSize: desktopFontSize.sm1,
      fontWeight: 600,
      color: colorPalette.red,
    },
    second: {
      children: "D",
      fontSize: desktopFontSize.sm1,
      fontWeight: 600,
      color: colorPalette.blue,
    },
    third: {
      children: "N",
      fontSize: desktopFontSize.sm1,
      fontWeight: 600,
      color: colorPalette.green,
    },
  },
  linkText: {
    about: {
      children: "About Us",
      fontSize: desktopFontSize.sm1,
      fontWeight: 500,
      color: colorPalette.secondary,
      padding: "0.25rem",
      url: "https://odn.us",
    },
    blog: {
      children: "Blog",
      fontSize: desktopFontSize.sm1,
      fontWeight: 500,
      color: colorPalette.secondary,
      padding: "0.25rem",
      url: "https://blog.naver.com/odnus",
    },
  },
  icons: [
    {
      maker: "AiIcon",
      iconName: "AiFillInstagram",
      cursor: "pointer",
      size: "2rem",
      padding: "1rem",
      color: colorPalette.secondary,
      url: "https://instagram.com/odn_promo",
    },
    {
      maker: "AiIcon",
      iconName: "AiFillFacebook",
      cursor: "pointer",
      size: "2rem",
      color: colorPalette.secondary,
      url: "https://facebook.com/odncorp",
    },
    {
      maker: "AiIcon",
      iconName: "AiFillYoutube",
      cursor: "pointer",
      size: "2.3rem",
      padding: "1rem",
      color: colorPalette.secondary,
      url: "https://youtube.com/@odncorp",
    },
  ],
};

export default props;
