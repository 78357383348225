import React from "react";
// import { Map } from "../../atoms";
import { MapCard } from "../../molecules";
import * as S from "./style";

const MapTemplate = (card) => {

	return (
		<S.Container>
			<MapCard {...card} height={"78vh"} />
		</S.Container>
	);
};

export default MapTemplate;
