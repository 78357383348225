/* eslint-disable jsx-a11y/alt-text */
import { Form, Input, Button } from 'antd'
import { signin, signUpKakao, signUpNaver } from '../../../api/auth'
import KakaoLogin from 'react-kakao-login'
import { useEffect } from 'react'
import { kakaoLogo, naverLogo } from '../../../config/images'

const { naver } = window

const initNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
        clientId: 'PVWAZlqwDXH764WbjwWA',
        callbackUrl: 'http://localhost:3000/authentication/sign-in',
        isPopup: true,
    })
    naverLogin.init()
}

const Signin = () => {
    useEffect(() => {
        let hash = window.location.hash?.split('=')
        if (hash.length > 1) {
            (async () => {
                let res = await signUpNaver({ access_token: hash[1].split('&')[0] })
                localStorage.setItem('token', res.data.access_token)
                window.location.href = window.location.origin
            })()
        }
        initNaverLogin()
    }, [])
    return (
        <Form
            initialValues={{
                username: '',
                password: ''
            }}
            style={{
                width: '98%',
                maxWidth: '400px',
                justifyContent: 'center',
                display: 'flex',
                margin: 'auto',
                flexDirection: 'column',
                height: '80vh'
            }}
            onFinish={async (values) => {
                let res = await signin({ ...values })
                if (res.status !== 200) {
                    return alert('로그인에 실패했습니다.')
                }
                localStorage.setItem('access_token', res.data.access_token)
                window.location.href = window.location.origin
            }}
        >
            <Form.Item name='username'>
                <Input />
            </Form.Item>
            <Form.Item name='password'>
                <Input type='password' />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button style={{ width: '48%' }} onClick={() => window.location.href = window.location.origin + '/authentication/sign-up'} >회원가입</Button>
                <Button htmlType='submit' style={{ width: '48%' }} type='primary'>
                    로그인
                </Button>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
                <KakaoLogin
                    token='a1775fa3231c73e67e8733ff37a80ad0'
                    onSuccess={async (e) => {
                        let res = await signUpKakao({ access_token: e.response.access_token })
                        localStorage.setItem('token', res.data.access_token)
                        window.location.href = window.location.origin
                    }}
                    render={({ onClick }) => {
                        return (
                            <div onClick={onClick} style={{ cursor: 'pointer', margin: '0 auto 0 0', display: 'flex', alignItems: 'center', justifyContent: "center", background: 'rgb(254, 229, 0)', borderRadius: '0.2rem', height: '41px', width: '48%' }}>
                                <img src={kakaoLogo} height={15} style={{ marginRight: '1rem' }} />
                                <span style={{ fontWeight: 'bold', color: 'rgb(24, 22, 0)' }}>카카오로 로그인</span>
                            </div>
                        )
                    }}
                    onFail={console.log}
                    onLogout={console.log} />
                <div id='naverIdLogin_loginButton' style={{ cursor: 'pointer', margin: '0 0 0 auto', display: 'flex', alignItems: 'center', justifyContent: "center", background: 'rgb(48, 200, 0)', borderRadius: '0.2rem', height: '41px', width: '48%' }} >
                    <img src={naverLogo} height={30} style={{ marginRight: '1rem' }} />
                    <span style={{ fontWeight: 'bold', color: 'white' }}>네이버로 로그인</span>
                </div>
            </div>

        </Form>
    )
}

export default Signin