import React from "react";
import styled from "styled-components";


const StyledSubTitle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${props => props.padding};
	font-weight: ${props => props.fontWeight};
	color: ${props => props.color};
    font-size: ${props => props.fontSize};
`;

const Span = styled.span`
    width: 100%;
    text-align: center;
`;


const SubTitle = ({ children, fontSize, fontWeight, padding = '1rem', color = 'inherit' }) => {
    const needProps = {
        color,
        fontSize,
        fontWeight,
        padding
    };
    return (
        <StyledSubTitle {...needProps}>
            <Span>
                {children}
            </Span>
        </StyledSubTitle>
    );
};

export default SubTitle