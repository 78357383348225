import React, { useEffect, useState } from "react"
import { Table as TableTemplate } from "../../components/template"
import { dataProps } from './data'
import { getConducts, getOxygens, getPhs } from '../../api/measure'
import { useRecoilValue } from "recoil"
import { deviceIdGetState } from "../../recoils/selectors"
import { measureToTableData, measureToAspectChartData } from "../../util/parser"
import { Spin } from "antd"

const getData = async (category, id) => {
    switch (category) {
        case 'oxygen':
            return await getOxygens({ id })
        case 'conduct':
            return await getConducts({ id })
        case 'ph':
            return await getPhs({ id })
        default:
            return {}
    }
}

const Table = () => {
    const id = useRecoilValue(deviceIdGetState)
    const [isLoading, setIsLoading] = useState(true)
    const [category, setCategory] = useState('oxygen')

    useEffect(() => {
        if (id !== null) {
            (async () => {
                setIsLoading(true)
                let res = await getData(category, id)
                let table_data = measureToTableData(res.data.results)
                dataProps.table = { ...dataProps.table, ...table_data }
                dataProps.card = {
                    ...dataProps.card,
                    ...measureToAspectChartData(res.data.results, category)
                }
                setIsLoading(false)
            })();
        }
    }, [id, category])
    dataProps.setCategory = (e) => setCategory(e)
    dataProps.category = category
    return (
        <React.Fragment>
            {isLoading && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
                    <Spin tip='데이터를 불러오는 중입니다.' />
                </div>
            )}
            {!isLoading && (<TableTemplate {...dataProps} />)}

        </React.Fragment>

    )
}

export default Table