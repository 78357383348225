import React from 'react';
import sideNavProps from './data'
import { SideNav as SideNavTemplate } from '../../components/template'


const SideNav = () => {
    return (
        <SideNavTemplate {...sideNavProps} />
    )
}
export default SideNav;