import React from "react";
import styled from "styled-components";
import { SearchInput, IconGroup } from '../../molecules'
import { viewSize } from '../../../config/palette'


const StyledInputSet = styled.div`
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    @media (min-width: ${viewSize.tablet} ){
        justify-content: stretch ;
        width: max-content;
    }
    @media (max-width: ${viewSize.mobile}){
        justify-content: space-between;
    }
`

const InputSet = ({ iconProps, searchProps }) => {
    return (
        <StyledInputSet>
            <SearchInput {...searchProps} />
            <IconGroup {...iconProps} />
        </StyledInputSet>
    )
}

export default InputSet