import { Chart, Card } from '../../atoms'

const ChartCard = ({ chart, ...card }) => {
    return (
        <Card {...card} width='48%'>
            <Chart.Line {...chart} />
        </Card>
    )
}

export default ChartCard